import React, { useState } from 'react';
import { ButtonSubmit } from '../button/Button';
import { navigate } from 'gatsby';

export const InternalLocatorForm = ({
  aboveInputText,
  inputPlaceholder,
  buttonSearchText,
  locatorLink,
  locatorAnchor,
}) => {
  const [inputValue, setInputvalue] = useState('');

  const actionWithoutAnchor = `/${locatorLink}?q=${inputValue}`;
  const action = locatorAnchor
    ? actionWithoutAnchor + `#${locatorAnchor}`
    : actionWithoutAnchor;

  function handleChange(event) {
    setInputvalue(event.target.value);
  }

  return (
    <form
      className='agency-locator__form'
      onSubmit={(e) => {
        e.preventDefault();
        navigate(action);
      }}
    >
      <div className='agency-locator__input-wrapper'>
        <label className='agency-locator__label' htmlFor='agency-locator-input'>
          {aboveInputText}
        </label>
        <input
          //name='alq'
          id='agency-locator-input'
          type='text'
          value={inputValue}
          onChange={handleChange}
          placeholder={inputPlaceholder}
          className='agency-locator__text-input'
          required
        />
      </div>
      <ButtonSubmit
        htmlFor={'agency-locator-submit'}
        className={'agency-locator__submit'}
        text={buttonSearchText}
        inputClassName='agency-locator__submit-input'
        type={'primary'}
      />
    </form>
  );
};

export default InternalLocatorForm;

import React, { useState, useEffect } from 'react';

export const ExternalLocator = ({ src }) => {
  const [iframeQuery, setIframeQuery] = useState('');
  const [showIframe, setShowIframe] = useState(false);
  useEffect(() => {
    const search = window.location.search;
    const query = search.split('?q=')[1] || ''; // split to retrieve query param
    setIframeQuery(query);
    setShowIframe(true);
  }, []);

  return (
    showIframe && (
      <iframe
        title='agency-locator-iframe'
        allow='geolocation'
        height='600px'
        src={iframeQuery ? `${src}?q=${iframeQuery}` : src}
      ></iframe>
    )
  );
};

export default ExternalLocator;

import React from 'react';
import classNames from 'classnames';
import InternalLocatorForm from './InternalLocatorForm';
import Icon from '../../atoms/icon';
import { Wrapper } from '../../utils/components';

const InternalLocator = ({
  aboveInputText,
  internalLocatorIcon,
  internalLocatorIconThickness,
  text,
  inputPlaceholder,
  buttonSearchText,
  locatorLink,
  locatorAnchor,
  descriptionTag,
}) => (
  <div className={classNames(`agency-locator `)}>
    <div className='agency-locator__box wrapper'>
      <div className='agency-locator__box-description'>
        <Icon
          icon={internalLocatorIcon}
          thickness={internalLocatorIconThickness}
          className='agency-locator__icon'
        />
        <Wrapper className='agency-locator__text' tag={descriptionTag || 'h2'}>
          {text}
        </Wrapper>
      </div>
      <InternalLocatorForm
        aboveInputText={aboveInputText}
        inputPlaceholder={inputPlaceholder}
        buttonSearchText={buttonSearchText}
        locatorLink={locatorLink}
        locatorAnchor={locatorAnchor}
      />
    </div>
  </div>
);

export default InternalLocator;
